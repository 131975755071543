.dot {
  width: 11px;
  height: 11px;
  background-color: #1e40af; /* Choose your desired dot color */
  border-radius: 50%;
  opacity: 0; /* Initially hide the dot */
  animation: moveUpDown 1.5s infinite alternate;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
    opacity: 0; /* Show the dot at the start of the animation */
  }
  50% {
    opacity: 1; /* Keep the dot visible while moving up */
  }
  100% {
    transform: translateY(
      -30px
    ); /* Adjust the distance you want the dot to move */
    opacity: 0; /* Hide the dot while moving down */
  }
}

.logo {
  animation: slide 20s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(
      calc(-100% - 10px)
    ); /* Adjust -10px to create space between logos */
  }
}

.slider-container {
  animation: none; /* Disable animation for the container */
  position: relative; /* Ensure proper positioning */
  width: 100vw; /* Allow container to expand horizontally */
}
/* Testimonials.css */

.testimonials-container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.testimonial-item {
  width: 100%;
  padding: 20px;
  transition: transform 2s linear infinite;
}

@media (min-width: 768px) {
  .testimonial-item {
    width: 50%; /* Show 2 columns on medium screens */
  }
}

.right-to-left {
  animation: moveRightToLeft 20s linear infinite;
}

.left-to-right {
  animation: moveRightToLeft 20s linear infinite;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.review {
  margin-top: 10px;
  font-size: 18px;
}

.name {
  font-weight: bold;
}

.designation {
  color: #777;
}

@media (min-width: 640px) {
  .faq-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
}

@media (min-width: 768px) {
  .faq-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .faq-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .faq-container {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.curosel-animation {
  transition: transform 0.5s ease-in-out;
}

.curosel-animation.slide-prev {
  transform: translateX(100%);
}

.curosel-animation.slide-next {
  transform: translateX(-100%);
}

.underlinestyle {
  position: relative;
}

.underlinestyle:after {
  content: '';
  position: absolute;
  width: 100%;
  border-top: solid 3px rgb(28, 0, 104);
  left: 0;
  bottom: -20px;
  border-radius: 50%;
  height: 12px;
}


@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 2s linear infinite;
}
